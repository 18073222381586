import App from './App.vue';
import router from "@/router";
import { createApp } from 'vue';

const app = createApp(App);
import mitt from 'mitt';
const emitter = mitt();
app.config.globalProperties.emitter = emitter;

app.use(router);
app.mount('#app');
