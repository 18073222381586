import { createRouter, createWebHistory } from "vue-router";

function lazyLoad(view) {
      return () => import(`../views/${view}.vue`)
}

const routes = [
      {
            path: '/login',
            name: 'login',
            component: lazyLoad('Login'),
            props: true
      },
      {
            path: '/signup',
            name: 'signup',
            component: lazyLoad('Signup'),
            props: true
      },
      {
            path: '/',
            name: 'homePage',
            component: lazyLoad('Home'),
            props: true
      },
      {
            path: '/availability',
            name: 'availabilityPage',
            component: lazyLoad('AvailabilityPage'),
            props: true
      },
      {
            path: '/allocations',
            name: 'allocationsPage',
            component: lazyLoad('Allocations'),
            props: true
      },
      {
            path: '/worksheetHistory',
            name: 'worksheetHistoryPage',
            component: lazyLoad('WorksheetHistory'),
            props: true
      },
      {
            path: '/analytics',
            name: 'analyticsPage',
            component: lazyLoad('Analytics'),
            props: true
      },
];

const router = new createRouter({
      history: createWebHistory(),
      routes,
      linkActiveClass: 'active-link',
      linkExactActiveClass: 'exact-active-link',
});

export default router;