<template>
    <div class="header">
        <div class="container-fluid">
            <div class="row">
                <div class="col-sm-6 col-12">
                    <div class="welcome">
                        <h4>
                            <svg id="home" xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18">
                                <g id="Home-2" data-name="Home">
                                    <path id="Home-3" data-name="Home" d="M6.307,16.894v-2.76a1.319,1.319,0,0,1,1.349-1.277H10.39a1.319,1.319,0,0,1,1.358,1.277h0V16.9A1.131,1.131,0,0,0,12.887,18H14.71A3.2,3.2,0,0,0,18,14.906h0V7.054a2.161,2.161,0,0,0-.911-1.714L10.855.617a3.138,3.138,0,0,0-3.737,0L.911,5.348A2.144,2.144,0,0,0,0,7.063v7.843A3.2,3.2,0,0,0,3.29,18H5.113a1.142,1.142,0,0,0,1.176-1.106h0" transform="translate(0 0)"/>
                                </g>
                            </svg>
                            Welcome Back, John!
                        </h4>
                    </div>
                </div>
                <div class="col-sm-6 col-12">
                    <div class="panel-search">
                        <svg xmlns="http://www.w3.org/2000/svg" width="19.811" height="19.81" viewBox="0 0 19.811 19.81">
                            <g id="Iconly_Light_Search" data-name="Iconly/Light/Search" transform="translate(0.75 0.75)">
                                <g id="Search">
                                <ellipse id="Ellipse_739" cx="8.622" cy="8.417" rx="8.622" ry="8.417" fill="none" stroke="#000" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" stroke-width="1.5"/>
                                <path id="Line_181" d="M0,0,3.38,3.291" transform="translate(14.62 14.708)" fill="none" stroke="#000" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" stroke-width="1.5"/>
                                </g>
                            </g>
                        </svg>
                        <input type="text" placeholder="search">
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'qoo-header',
    }
</script>

<style lang='scss' scoped>
    @import 'Header';
</style>