<template>
  <div class="qoo-main">

    <div class="col-left" v-if="currentRouteName !== 'login' && currentRouteName !== 'signup'">
      <QooSidenav/>
    </div>

    <div class="col-right" :class="{'w-100': currentRouteName === 'login' || currentRouteName === 'signup' }">
      
      <QooHeader v-if="currentRouteName !== 'login' && currentRouteName !== 'signup'"/>

      <div class="container-fluid" :class="{'p-0': currentRouteName === 'login' || currentRouteName === 'signup' }">
        <router-view></router-view>
      </div>

    </div>
  </div>
</template>

<script>

import QooHeader from "@/components/common/header/Header";
import QooSidenav from "@/components/common/sidenav/Sidenav.vue";

export default {
  name: 'App',
  components: { QooHeader , QooSidenav },
  computed: {
    currentRouteName() {
      return this.$route.name;
    }
  }
}

</script>