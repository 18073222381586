<template>
    <div class="sidenav">
        <div class="sidebav-top">
            <a href="#" class="panel-brand">
                <img src="../../../assets/images/login-icon.svg" alt="brand-icon">
            </a>
            <div class="user">
                <img src="../../../assets/images/user-default.png" alt="user-default">
                <svg xmlns="http://www.w3.org/2000/svg" width="10.121" height="5.811" viewBox="0 0 10.121 5.811">
                    <g id="Arrow_-_Down_2" data-name="Arrow - Down 2" transform="translate(1.061 1.061)">
                        <path id="Stroke_1" data-name="Stroke 1" d="M8,0,4,4,0,0" fill="none" stroke="#000" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" stroke-width="1.5"/>
                    </g>
                </svg>
            </div>
        </div>
        <ul class="menu">
            <li>
                <router-link to="/">
                    <svg xmlns="http://www.w3.org/2000/svg" width="19.5" height="19.605" viewBox="0 0 19.5 19.605">
                        <g id="home" transform="translate(0.75 0.855)" opacity="0.2">
                            <g id="Home-2" data-name="Home">
                            <path id="Home-3" data-name="Home" d="M6.307,16.894v-2.76a1.319,1.319,0,0,1,1.349-1.277H10.39a1.319,1.319,0,0,1,1.358,1.277h0V16.9A1.131,1.131,0,0,0,12.887,18H14.71A3.2,3.2,0,0,0,18,14.906h0V7.054a2.161,2.161,0,0,0-.911-1.714L10.855.617a3.138,3.138,0,0,0-3.737,0L.911,5.348A2.144,2.144,0,0,0,0,7.063v7.843A3.2,3.2,0,0,0,3.29,18H5.113a1.142,1.142,0,0,0,1.176-1.106h0" transform="translate(0 0)" fill="none" stroke="#000" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" stroke-width="1.5"/>
                            </g>
                        </g>
                    </svg>
                </router-link>
                <svg class="active-arrow" xmlns="http://www.w3.org/2000/svg" width="16" height="36" viewBox="0 0 16 36">
                    <path id="Path_54" data-name="Path 54" d="M11.87-2.257c4.606-7.1,5.4-5.774,5.4-5.774v35.5s-.133,2.539-5.4-5.167-10.6-6.34-10.6-12.48S7.265,4.841,11.87-2.257Z" transform="translate(-1.274 8.128)"/>
                </svg>
            </li>
            <li>
                <router-link to="/analytics">
                    <svg xmlns="http://www.w3.org/2000/svg" width="19.501" height="19.566" viewBox="0 0 19.501 19.566">
                        <g id="analytics" transform="translate(0.75 0.75)" opacity="0.2">
                            <g id="Graph">
                            <path id="Path_33948" d="M8.348,6.533A7.91,7.91,0,0,0,.711,0,.694.694,0,0,0,.219.178.662.662,0,0,0,0,.643H0V.7L.43,6.987a.784.784,0,0,0,.834.72l6.452-.42a.69.69,0,0,0,.469-.232.658.658,0,0,0,.163-.488Z" transform="translate(9.65 0)" fill="none" stroke="#000" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" stroke-width="1.5"/>
                            <path id="Path_33949" d="M5.893.022a.883.883,0,0,1,1,.489.758.758,0,0,1,.079.283c.088,1.217.272,3.883.377,5.323a.969.969,0,0,0,.342.672,1.016,1.016,0,0,0,.729.237h0L13.837,6.7a.89.89,0,0,1,.655.232.848.848,0,0,1,.275.625h0A7.264,7.264,0,0,1,9,14.113,7.447,7.447,0,0,1,.934,10.549,6.747,6.747,0,0,1,.056,7.926,4.321,4.321,0,0,1,0,7.068,7.262,7.262,0,0,1,5.884.022" transform="translate(0 3.716)" fill="none" stroke="#000" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" stroke-width="1.5"/>
                            </g>
                        </g>
                    </svg>
                </router-link>
                <svg class="active-arrow" xmlns="http://www.w3.org/2000/svg" width="16" height="36" viewBox="0 0 16 36">
                    <path id="Path_54" data-name="Path 54" d="M11.87-2.257c4.606-7.1,5.4-5.774,5.4-5.774v35.5s-.133,2.539-5.4-5.167-10.6-6.34-10.6-12.48S7.265,4.841,11.87-2.257Z" transform="translate(-1.274 8.128)"/>
                </svg>
            </li>
            <li>
                <router-link to="/availability">
                    <svg xmlns="http://www.w3.org/2000/svg" width="19.5" height="19.5" viewBox="0 0 19.5 19.5">
                        <g id="availability" transform="translate(0.75 0.75)" opacity="0.2">
                            <g id="Tick_Square" data-name="Tick Square">
                            <path id="Stroke_1" data-name="Stroke 1" d="M13.217,0H4.782C1.843,0,0,2.081,0,5.026v7.947C0,15.919,1.834,18,4.782,18h8.434C16.165,18,18,15.919,18,12.974V5.026C18,2.081,16.165,0,13.217,0Z" fill="none" stroke="#000" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" stroke-width="1.5"/>
                            <path id="Stroke_3" data-name="Stroke 3" d="M0,2.309,2.31,4.618,6.928,0" transform="translate(5.536 6.691)" fill="none" stroke="#000" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" stroke-width="1.5"/>
                            </g>
                        </g>
                    </svg>
                </router-link>
                <svg class="active-arrow" xmlns="http://www.w3.org/2000/svg" width="16" height="36" viewBox="0 0 16 36">
                    <path id="Path_54" data-name="Path 54" d="M11.87-2.257c4.606-7.1,5.4-5.774,5.4-5.774v35.5s-.133,2.539-5.4-5.167-10.6-6.34-10.6-12.48S7.265,4.841,11.87-2.257Z" transform="translate(-1.274 8.128)"/>
                </svg>
            </li>
            <li> 
                <router-link to="/allocations">
                    <svg xmlns="http://www.w3.org/2000/svg" width="19.5" height="19.5" viewBox="0 0 19.5 19.5">
                        <g id="allocations" transform="translate(0.75 0.75)" opacity="0.2">
                            <g id="Category">
                            <path id="Stroke_1" data-name="Stroke 1" d="M2.2,0h2.94a2.213,2.213,0,0,1,2.2,2.223V5.188a2.214,2.214,0,0,1-2.2,2.223H2.2A2.214,2.214,0,0,1,0,5.188V2.223A2.214,2.214,0,0,1,2.2,0Z" transform="translate(10.653)" fill="none" stroke="#000" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" stroke-width="1.5"/>
                            <path id="Stroke_3" data-name="Stroke 3" d="M2.2,0H5.143a2.214,2.214,0,0,1,2.2,2.223V5.188a2.214,2.214,0,0,1-2.2,2.223H2.2A2.214,2.214,0,0,1,0,5.188V2.223A2.214,2.214,0,0,1,2.2,0Z" fill="none" stroke="#000" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" stroke-width="1.5"/>
                            <path id="Stroke_5" data-name="Stroke 5" d="M2.2,0H5.143a2.214,2.214,0,0,1,2.2,2.224V5.188a2.214,2.214,0,0,1-2.2,2.223H2.2A2.214,2.214,0,0,1,0,5.188V2.224A2.214,2.214,0,0,1,2.2,0Z" transform="translate(0 10.589)" fill="none" stroke="#000" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" stroke-width="1.5"/>
                            <path id="Stroke_7" data-name="Stroke 7" d="M2.2,0h2.94a2.214,2.214,0,0,1,2.2,2.224V5.188a2.213,2.213,0,0,1-2.2,2.223H2.2A2.214,2.214,0,0,1,0,5.188V2.224A2.214,2.214,0,0,1,2.2,0Z" transform="translate(10.653 10.589)" fill="none" stroke="#000" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" stroke-width="1.5"/>
                            </g>
                        </g>
                    </svg>
                </router-link>
                <svg class="active-arrow" xmlns="http://www.w3.org/2000/svg" width="16" height="36" viewBox="0 0 16 36">
                    <path id="Path_54" data-name="Path 54" d="M11.87-2.257c4.606-7.1,5.4-5.774,5.4-5.774v35.5s-.133,2.539-5.4-5.167-10.6-6.34-10.6-12.48S7.265,4.841,11.87-2.257Z" transform="translate(-1.274 8.128)"/>
                </svg>
            </li>
            <li>
                <router-link to="/worksheetHistory">
                    <svg xmlns="http://www.w3.org/2000/svg" width="17.5" height="19.5" viewBox="0 0 17.5 19.5">
                        <g id="worksheet_history" data-name="worksheet history" transform="translate(0.75 0.75)" opacity="0.2">
                            <g id="Paper">
                            <path id="Stroke_1" data-name="Stroke 1" d="M10.789,0H3.944A3.846,3.846,0,0,0,0,3.655v10.5a3.843,3.843,0,0,0,3.944,3.833h8.219A3.9,3.9,0,0,0,16,14.155V5.171Z" transform="translate(0 0.011)" fill="none" stroke="#000" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" stroke-width="1.5"/>
                            <path id="Stroke_3" data-name="Stroke 3" d="M0,0V2.851A2.588,2.588,0,0,0,2.642,5.375c1.355,0,2.741,0,2.834,0" transform="translate(10.519)" fill="none" stroke="#000" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" stroke-width="1.5"/>
                            <path id="Stroke_5" data-name="Stroke 5" d="M5.552.5H0" transform="translate(4.771 12.053)" fill="none" stroke="#000" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" stroke-width="1.5"/>
                            <path id="Stroke_7" data-name="Stroke 7" d="M3.453.5H0" transform="translate(4.77 7.199)" fill="none" stroke="#000" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" stroke-width="1.5"/>
                            </g>
                        </g>
                    </svg>
                </router-link>
                <svg class="active-arrow" xmlns="http://www.w3.org/2000/svg" width="16" height="36" viewBox="0 0 16 36">
                    <path id="Path_54" data-name="Path 54" d="M11.87-2.257c4.606-7.1,5.4-5.774,5.4-5.774v35.5s-.133,2.539-5.4-5.167-10.6-6.34-10.6-12.48S7.265,4.841,11.87-2.257Z" transform="translate(-1.274 8.128)"/>
                </svg>
            </li>
        </ul>
        <a href="/login" class="logout">
            <svg xmlns="http://www.w3.org/2000/svg" width="19.5" height="19.5" viewBox="0 0 19.5 19.5">
                <g id="Logout" transform="translate(0.75 0.75)">
                    <g id="Logout-2" data-name="Logout">
                    <path id="Stroke_1" data-name="Stroke 1" d="M11.577,4.493V3.585A3.536,3.536,0,0,0,8.093,0H3.483A3.536,3.536,0,0,0,0,3.585V14.415A3.536,3.536,0,0,0,3.483,18H8.1a3.526,3.526,0,0,0,3.475-3.575v-.918" fill="none" stroke="#c72c1a" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" stroke-width="1.5"/>
                    <path id="Stroke_3" data-name="Stroke 3" d="M11.385.5H0" transform="translate(6.615 8.5)" fill="none" stroke="#c72c1a" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" stroke-width="1.5"/>
                    <path id="Stroke_5" data-name="Stroke 5" d="M0,0,2.768,2.836,0,5.673" transform="translate(15.231 6.164)" fill="none" stroke="#c72c1a" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" stroke-width="1.5"/>
                    </g>
                </g>
            </svg>
        </a>
    </div>
</template>

<script>
    export default {
        name: 'qoo-sidenav',
    }
</script>

<style lang='scss' scoped>
    @import 'Sidenav';
</style>